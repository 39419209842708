import Joi from "joi";
import i18next from "i18next";
const schema = {
  //email
  email: Joi.string()
    .min(2)
    .max(100)
    .email({ tlds: { allow: false } })
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "string.min":
          case "string.max":
          case "string.email":
            err.message = i18next.t("validation.email");
            break;
          case "any.required":
            err.message = i18next.t("valdiation.email");
            break;
          default:
            break;
        }
      });

      return errors;
    }),
};

export { schema };
