import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import "moment/min/locales.min";

// Components
import MainBooking from "../components/booking/MainBooking";
import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";
import Loading from "../components/Loading";

// Hooks
import useLanguages from "../hooks/useLanguages";

// Services
import { bookingAction } from "../store/features/booking/bookingSlice";
import useHttpPrivate from "../hooks/useHttpPrivate";

import { API_BACKEND } from "../utils/api";
import { is_free_day, free_day_event } from "../utils/functions";

const Booking = () => {
  // Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();

  // Booking Store
  const dispatch = useDispatch();
  const { totalPrice } = useSelector((state) => state.booking);

  // Routing
  const { id } = useParams();
  const navigate = useNavigate();

  // Http private Auth
  const httpPrivate = useHttpPrivate();

  // States
  const [event, setEvent] = useState();
  const [bookingClosed, setBookingClosed] = useState();
  const [loading, setLoading] = useState(true);

  // Methods

  // Check if booking closed

  /*useEffect(() => {
    const langLocale = currentLangCode === "ar" ? "ar_DZ" : currentLangCode;
    moment.locale(langLocale);

    const fetchData = async () => {
      try {
        const { data } = await httpPrivate.get(`${API_BACKEND}/event/${id}`);

        setEvent(data.event);

        //Dispatch Total Price and Ticket information
        dispatch(bookingAction.setTicket(data.event.Tickets[0]));
        dispatch(bookingAction.setTotalPrice(data.event.Tickets[0].price));
        setLoading(loading);
        console.log(data);
      } catch (err) {
        navigate("/login");
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentLangCode]);*/

  return (
    <>
      <NavbarResponisve />
      <div className="booking">
        <div className="container">
          {!is_free_day() ? (
            <div className="alert alert-warning" style={{ fontWeight: "bold" }}>
              {t("alert_ticket_student_artist")}
            </div>
          ) : (
            <div className="alert alert-success" style={{ fontWeight: "bold" }}>
              {t(free_day_event())}
            </div>
          )}

          <div className="row">
            {!bookingClosed && (
              <div className="col-md-7">
                <h1 className="title">{t("achat_ticket")}</h1>

                <div className="booking-form">
                  <MainBooking />
                </div>
              </div>
            )}
            <div className={bookingClosed ? "col-md-12" : "col-md-5"}>
              <h3>{t("ticket_prices")}</h3>
              <div className="ticket-information">
                <h3>
                  <i class="fa-solid fa-person"></i> {t("visitors_general")}:{" "}
                  <span>200{t("DA")}</span>
                </h3>

                <h3>
                  <i class="fa-solid fa-address-card"></i>{" "}
                  {t("visitors_artist")}:<span>140 {t("DA")} </span>
                </h3>

                <h3>
                  <i class="fa-solid fa-graduation-cap"></i>{" "}
                  {t("visitors_student")}:<span>100 {t("DA")}</span>
                </h3>

                <h3>
                  <i class="fa-solid fa-people-group"></i>{" "}
                  {t("promo_ticket_price")}
                </h3>
                <h3>
                  <i class="fa-solid fa-key"></i> {t("handicap_ticket_price")}:
                </h3>
                <h3>
                  <i class="fa-regular fa-calendar-check"></i> {t("free_days")}:
                </h3>
              </div>
              <div class="satim-call">
                <img src="https://satim.dz/images/cal.png" alt="" />
                <img
                  src="https://certweb.satim.dz/img/logo-interoperabilite-final.png"
                  width="65px"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
