import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "moment/min/locales.min";

// Components
import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";

// Hooks
import useLanguages from "../hooks/useLanguages";

// Services
import useHttpPrivate from "../hooks/useHttpPrivate";

import { API_BACKEND } from "../utils/api";

const Statistics = () => {
  // Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();

  // Routing
  const navigate = useNavigate();

  // Http private Auth
  const httpPrivate = useHttpPrivate();

  // States
  const currentyear = new Date().getFullYear();
  const [months, setMonths] = useState();
  const [emonths, setEmonths] = useState();
  const [allYears, setAllYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentyear);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await httpPrivate(
          `${API_BACKEND}/visitors/statistics/${selectedYear}`
        );
        setAllYears((prev) => [...data.allYears]);
        setMonths({
          january: data.january,
          february: data.february,
          march: data.march,
          april: data.april,
          may: data.may,
          june: data.june,
          july: data.july,
          august: data.august,
          september: data.september,
          october: data.october,
          november: data.november,
          december: data.december,
        });

        setEmonths({
          eJanuary: data.eJanuary,
          eFebruary: data.eFebruary,
          eMarch: data.eMarch,
          eApril: data.eApril,
          eMay: data.eMay,
          eJune: data.eJune,
          eJuly: data.eJuly,
          eAugust: data.eAugust,
          eSeptember: data.eSeptember,
          eOctober: data.eOctober,
          eNovember: data.eNovember,
          eDecember: data.eDecember,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedYear]);

  // Methods
  const totalStats = (months, emonths) => {
    const totalMonthsIntern =
      parseInt(months.january[0].intern) +
      parseInt(months.february[0].intern) +
      parseInt(months.march[0].intern) +
      parseInt(months.april[0].intern + months.may[0].intern) +
      parseInt(months.june[0].intern) +
      parseInt(months.july[0].intern) +
      parseInt(months.august[0].intern) +
      parseInt(months.september[0].intern) +
      parseInt(months.october[0].intern) +
      parseInt(months.november[0].intern) +
      parseInt(months.december[0].intern);
    const totalMonthsExtern =
      parseInt(months.january[0].extern) +
      parseInt(months.february[0].extern) +
      parseInt(months.march[0].extern) +
      parseInt(months.april[0].extern + months.may[0].extern) +
      parseInt(months.june[0].extern) +
      parseInt(months.july[0].extern) +
      parseInt(months.august[0].extern) +
      parseInt(months.september[0].extern) +
      parseInt(months.october[0].extern) +
      parseInt(months.november[0].extern) +
      parseInt(months.december[0].extern);
    const totalMonthsResearcher =
      parseInt(months.january[0].researcher) +
      parseInt(months.february[0].researcher) +
      parseInt(months.march[0].researcher) +
      parseInt(months.april[0].researcher + months.may[0].researcher) +
      parseInt(months.june[0].researcher) +
      parseInt(months.july[0].researcher) +
      parseInt(months.august[0].researcher) +
      parseInt(months.september[0].researcher) +
      parseInt(months.october[0].researcher) +
      parseInt(months.november[0].researcher) +
      parseInt(months.december[0].researcher);
    const totalMonthsDelegation =
      parseInt(months.january[0].delegation) +
      parseInt(months.february[0].delegation) +
      parseInt(months.march[0].delegation) +
      parseInt(months.april[0].delegation + months.may[0].delegation) +
      parseInt(months.june[0].delegation) +
      parseInt(months.july[0].delegation) +
      parseInt(months.august[0].delegation) +
      parseInt(months.september[0].delegation) +
      parseInt(months.october[0].delegation) +
      parseInt(months.november[0].delegation) +
      parseInt(months.december[0].delegation);
    const totalMonthsEpayment =
      emonths.eJanuary[0].total +
      emonths.eFebruary[0].total +
      emonths.eMarch[0].total +
      emonths.eApril[0].total +
      emonths.eMay[0].total +
      emonths.eJune[0].total +
      emonths.eJuly[0].total +
      emonths.eAugust[0].total +
      emonths.eSeptember[0].total +
      emonths.eOctober[0].total +
      emonths.eNovember[0].total +
      emonths.eDecember[0].total;

    const totalJanuary =
      parseInt(months?.january[0]?.intern) +
      parseInt(months?.january[0]?.extern) +
      parseInt(months?.january[0]?.researcher) +
      parseInt(months?.january[0]?.delegation) +
      emonths?.eJanuary[0]?.total;
    const totalFebruary =
      parseInt(months.february[0].intern) +
      parseInt(months.february[0].extern) +
      parseInt(months.february[0].researcher) +
      parseInt(months.february[0].delegation) +
      emonths.eFebruary[0].total;
    const totalMarch =
      parseInt(months.march[0].intern) +
      parseInt(months.march[0].extern) +
      parseInt(months.march[0].researcher) +
      parseInt(months.march[0].delegation) +
      emonths.eMarch[0].total;
    const totalApril =
      parseInt(months.april[0].intern) +
      parseInt(months.april[0].extern) +
      parseInt(months.april[0].researcher) +
      parseInt(months.april[0].delegation) +
      emonths.eApril[0].total;

    const totalMay =
      parseInt(months.may[0].intern) +
      parseInt(months.may[0].extern) +
      parseInt(months.may[0].researcher) +
      parseInt(months.may[0].delegation) +
      emonths.eMay[0].total;
    const totalJune =
      parseInt(months.june[0].intern) +
      parseInt(months.june[0].extern) +
      parseInt(months.june[0].researcher) +
      parseInt(months.june[0].delegation) +
      emonths.eJune[0].total;
    const totalJuly =
      parseInt(months.july[0].intern) +
      parseInt(months.july[0].extern) +
      parseInt(months.july[0].researcher) +
      parseInt(months.july[0].delegation) +
      emonths.eJuly[0].total;
    const totalAugust =
      parseInt(months.august[0].intern) +
      parseInt(months.august[0].extern) +
      parseInt(months.august[0].researcher) +
      parseInt(months.august[0].delegation) +
      emonths.eAugust[0].total;
    const totalSeptember =
      parseInt(months.september[0].intern) +
      parseInt(months.september[0].extern) +
      parseInt(months.september[0].researcher) +
      parseInt(months.september[0].delegation) +
      emonths.eSeptember[0].total;

    const totalOctober =
      parseInt(months.october[0].intern) +
      parseInt(months.october[0].extern) +
      parseInt(months.october[0].researcher) +
      parseInt(months.october[0].delegation) +
      emonths.eOctober[0].total;

    const totalNovember =
      parseInt(months.november[0].intern) +
      parseInt(months.november[0].extern) +
      parseInt(months.november[0].researcher) +
      parseInt(months.november[0].delegation) +
      emonths.eNovember[0].total;
    const totalDecember =
      parseInt(months.december[0].intern) +
      parseInt(months.december[0].extern) +
      parseInt(months.december[0].researcher) +
      parseInt(months.december[0].delegation) +
      emonths.eDecember[0].total;

    const totalStatistics =
      totalMonthsIntern +
      totalMonthsExtern +
      totalMonthsResearcher +
      totalMonthsDelegation +
      totalMonthsEpayment;

    return {
      totalJanuary,
      totalFebruary,
      totalMarch,
      totalApril,
      totalMay,
      totalJune,
      totalJuly,
      totalAugust,
      totalSeptember,
      totalOctober,
      totalNovember,
      totalDecember,
      totalMonthsIntern,
      totalMonthsExtern,
      totalMonthsResearcher,
      totalMonthsDelegation,
      totalMonthsEpayment,
      totalStatistics,
    };
  };
  return (
    <>
      <NavbarResponisve />
      <div className="booking">
        <div className="container">
          <div className="row">
            {isLoading ? (
              <>loading</>
            ) : (
              <>
                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "12px",
                    }}
                  >
                    <h2>{t("statistics_year")}</h2>
                    <form>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        style={{ width: "auto" }}
                      >
                        {allYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </form>
                  </div>
                  <table className="table table-striped table-bordered table-dark">
                    <thead>
                      <tr>
                        <th>{t("months_txt")}</th>
                        <th>{t("nbr_visitors_intern")}</th>
                        <th>{t("nbr_visitors_extern")}</th>
                        <th>{t("nbr_visitors_researcher")}</th>
                        <th>{t("nbr_visitors_delegation")}</th>
                        <th>{t("nbr_visitors_epaiment")}</th>
                        <th>{t("total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{t("months.january")}</th>
                        <td> {months.january[0].intern}</td>
                        <td>{months.january[0].extern}</td>
                        <td>{months.january[0].researcher}</td>
                        <td>{months.january[0].delegation}</td>
                        <td>{emonths.eJanuary[0].total}</td>
                        <td>{totalStats(months, emonths).totalJanuary}</td>
                      </tr>
                      <tr>
                        <th>{t("months.february")}</th>
                        <td> {months.february[0].intern}</td>
                        <td>{months.february[0].extern}</td>
                        <td>{months.february[0].researcher}</td>
                        <td>{months.february[0].delegation}</td>
                        <td>{emonths.eFebruary[0].total}</td>
                        <td>{totalStats(months, emonths).totalFebruary}</td>
                      </tr>
                      <tr>
                        <th>{t("months.march")}</th>
                        <td> {months.march[0].intern}</td>
                        <td>{months.march[0].extern}</td>
                        <td>{months.march[0].researcher}</td>
                        <td>{months.march[0].delegation}</td>
                        <td>{emonths.eMarch[0].total}</td>
                        <td>{totalStats(months, emonths).totalMarch}</td>
                      </tr>

                      <tr>
                        <th>{t("months.april")}</th>
                        <td> {months.april[0].intern}</td>
                        <td>{months.april[0].extern}</td>
                        <td>{months.april[0].researcher}</td>
                        <td>{months.april[0].delegation}</td>
                        <td>{emonths.eApril[0].total}</td>
                        <td>{totalStats(months, emonths).totalApril}</td>
                      </tr>
                      <tr>
                        <th>{t("months.may")}</th>
                        <td> {months.may[0].intern}</td>
                        <td>{months.may[0].extern}</td>
                        <td>{months.may[0].researcher}</td>
                        <td>{months.may[0].delegation}</td>
                        <td>{emonths.eMay[0].total}</td>
                        <td>{totalStats(months, emonths).totalMay}</td>
                      </tr>
                      <tr>
                        <th>{t("months.june")}</th>
                        <td> {months.june[0].intern}</td>
                        <td>{months.june[0].extern}</td>
                        <td>{months.june[0].researcher}</td>
                        <td>{months.june[0].delegation}</td>
                        <td>{emonths.eJune[0].total}</td>
                        <td>{totalStats(months, emonths).totalJune}</td>
                      </tr>
                      <tr>
                        <th>{t("months.july")}</th>
                        <td> {months.july[0].intern}</td>
                        <td>{months.july[0].extern}</td>
                        <td>{months.july[0].researcher}</td>
                        <td>{months.july[0].delegation}</td>
                        <td>{emonths.eJuly[0].total}</td>
                        <td>{totalStats(months, emonths).totalJuly}</td>
                      </tr>
                      <tr>
                        <th>{t("months.august")}</th>
                        <td> {months.august[0].intern}</td>
                        <td>{months.august[0].extern}</td>
                        <td>{months.august[0].researcher}</td>
                        <td>{months.august[0].delegation}</td>
                        <td>{emonths.eAugust[0].total}</td>
                        <td>{totalStats(months, emonths).totalAugust}</td>
                      </tr>
                      <tr>
                        <th>{t("months.september")}</th>
                        <td> {months.september[0].intern}</td>
                        <td>{months.september[0].extern}</td>
                        <td>{months.september[0].researcher}</td>
                        <td>{months.september[0].delegation}</td>
                        <td>{emonths.eSeptember[0].total}</td>
                        <td>{totalStats(months, emonths).totalSeptember}</td>
                      </tr>
                      <tr>
                        <th>{t("months.october")}</th>
                        <td> {months.october[0].intern}</td>
                        <td>{months.october[0].extern}</td>
                        <td>{months.october[0].researcher}</td>
                        <td>{months.october[0].delegation}</td>
                        <td>{emonths.eOctober[0].total}</td>
                        <td>{totalStats(months, emonths).totalOctober}</td>
                      </tr>
                      <tr>
                        <th>{t("months.november")}</th>
                        <td> {months.november[0].intern}</td>
                        <td>{months.november[0].extern}</td>
                        <td>{months.november[0].researcher}</td>
                        <td>{months.november[0].delegation}</td>
                        <td>{emonths.eNovember[0].total}</td>
                        <td>{totalStats(months, emonths).totalNovember}</td>
                      </tr>
                      <tr>
                        <th>{t("months.december")}</th>
                        <td> {months.december[0].intern}</td>
                        <td>{months.december[0].extern}</td>
                        <td>{months.december[0].researcher}</td>
                        <td>{months.december[0].delegation}</td>
                        <td>{emonths.eDecember[0].total}</td>
                        <td>{totalStats(months, emonths).totalDecember}</td>
                      </tr>

                      <tr>
                        <th>{t("total")}</th>
                        <td>{totalStats(months, emonths).totalMonthsIntern}</td>
                        <td>{totalStats(months, emonths).totalMonthsExtern}</td>
                        <td>
                          {totalStats(months, emonths).totalMonthsResearcher}
                        </td>
                        <td>
                          {totalStats(months, emonths).totalMonthsDelegation}
                        </td>
                        <td>
                          {totalStats(months, emonths).totalMonthsEpayment}
                        </td>
                        <td>{totalStats(months, emonths).totalStatistics}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default Statistics;
