import { useState, useRef } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
import Form from "../../UI/form/Form";
import Field from "../../UI/form/Field";
import { schema } from "./validation";
import { validateForm, validateProprety } from "../../../utils/validation";
import { register } from "../../../services/user";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterForm = (props) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    telephone: "",
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    telephone: false,
    username: false,
    email: false,
    password: false,
    repeatPassword: false,
    error: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const recaptchaRef = useRef();

  /// Functions

  // Reacaptcha Handler
  const onChangeRecaptcha = async (value) => {
    setRecaptchaToken(value);
    setErrors({ ...errors, error: false });
  };
  //Onchange Input Handler
  const onChangeHandler = (e) => {
    let newError;

    const newData = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(newData);

    const error = validateProprety(
      schema,
      e.target.name,
      e.target.value,
      newData
    );
    newError = { ...errors, ...error };

    setErrors(newError);
  };

  //Submit Handler
  const submitHandler = async (e) => {
    e.preventDefault();

    // Validate Human
    if (!recaptchaToken)
      return setErrors({ ...errors, error: t("validation.robot") });

    // Validate Data
    const validation = validateForm(schema, form);
    const isValid = _.pick(validation, "isValidForm").isValidForm;
    const newErrors = _.omit(validation, "isValidForm");

    setErrors(newErrors);

    if (isValid) {
      setIsLoading(true);
      const response = await register(form, recaptchaToken);

      if (response.error) {
        console.log(response.error);
        if (response.error.code === "RECAPTCHA_FAILED") {
          setErrors({ ...errors, error: t("validation.robot") });
        }
        if (response.error.code === "USERNAME_EXISTS") {
          setErrors({ ...errors, username: t(response.error.message) });
        }
        if (response.error.code === "EMAIL_EXISTS") {
          setErrors({ ...errors, email: t(response.error.message) });
        }
      }

      if (response.response_data) {
        setForm({
          first_name: "",
          last_name: "",
          telephone: "",
          username: "",
          email: "",
          password: "",
          repeatPassword: "",
        });
        toast.success(t("register_success"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => (window.location = "/"), 3000);
      }
    }
    setIsLoading(false);
  };
  return (
    <>
      <Form
        onSubmit={submitHandler}
        errorForm={errors.error}
        isLoading={isLoading}
      >
        <Field
          label={`${t("form.first_name")}:`}
          name="first_name"
          type="text"
          placeholder="Ex: Aladdin"
          onChange={onChangeHandler}
          value={form.first_name}
          error={errors.first_name}
        />
        <Field
          label={`${t("form.last_name")}:`}
          name="last_name"
          type="text"
          placeholder="Ex: Benflane"
          onChange={onChangeHandler}
          value={form.last_name}
          error={errors.last_name}
        />
        <Field
          label={`${t("form.telephone")}:`}
          name="telephone"
          type="text"
          placeholder="Ex: 0667777777"
          onChange={onChangeHandler}
          value={form.telephone}
          error={errors.telephone}
        />
        <Field
          label={`${t("form.username")}:`}
          name="username"
          type="text"
          placeholder="Ex: AhmedBen"
          onChange={onChangeHandler}
          value={form.username}
          error={errors.username}
        />
        <Field
          label={`${t("form.email")}:`}
          name="email"
          type="email"
          placeholder="Ex: ahmed@gmail.com"
          onChange={onChangeHandler}
          value={form.email}
          error={errors.email}
        />

        <Field
          label={`${t("form.password")}:`}
          name="password"
          type="password"
          placeholder=""
          onChange={onChangeHandler}
          value={form.password}
          error={errors.password}
        />
        <Field
          label={`${t("form.repeat_password")}:`}
          name="repeatPassword"
          type="password"
          onChange={onChangeHandler}
          value={form.repeatPassword}
          error={errors.repeatPassword}
        />
        {!isLoading && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcHVAopAAAAAHArWr06U3_DqRstBI0jzdDoeODf"
            onChange={onChangeRecaptcha}
          />
        )}
        {!isLoading && (
          <button className="btn btn-form">{t("form.sign_up")}</button>
        )}
        {isLoading && <div className="btn btn-form">{t("loading")}</div>}
      </Form>
      <Link to="/" style={{ textAlign: "center", display: "block" }}>
        {t("back_home")}
      </Link>
      <ToastContainer />
    </>
  );
};

export default RegisterForm;
