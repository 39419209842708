import Joi from "joi";
import _ from "lodash";
// Validate Proprety
// Only one Field exemple: [First name]
const validateProprety = (validation, name, value, formData = null) => {
  console.log("validate props", name, value);
  let schema;
  let error;
  const options = { abortEarly: false };
  console.log(formData);
  if (name === "repeatPassword") {
    schema = Joi.object({
      password: validation["password"],
      repeatPassword: validation["repeatPassword"],
    });
    const { error: errorJoi } = schema.validate(
      {
        password: formData["password"],
        repeatPassword: formData["repeatPassword"],
      },
      options
    );
    error = errorJoi;
  } else {
    schema = Joi.object({ [name]: validation[name] });
    const { error: errorJoi } = schema.validate({ [name]: value }, options);
    console.log(validation)
    error = errorJoi;
  }

  //const { error } = schema.validate({ [name]: value }, options);

  if (!error) return { [name]: false };

  console.log(error.details[0].message)

  return { [name]: error.details[0].message };
};

// Validate Form
const validateForm = (validation, data) => {
  const schema = Joi.object({ ...validation });
  const options = { abortEarly: false };
  let errors;
  try {
    const validationResult = schema.validate({ ...data }, options);

    // No errors
    if (!validationResult.error) {
      for (const name in data) {
        errors = { ...errors, [name]: false };
      }

      return { ...errors, isValidForm: true };
    }

    validationResult.error?.details?.forEach((err) => {
      console.log(errors);
      errors = { ...errors, [err.path[0]]: err.message };
    });
    return { ...errors, isValidForm: false };
  } catch (err) {
    console.log(err);
    return { any: "Something wrong happened", isValidForm: false };
  }
};

// Validate Multiple Form Data
const valdiateMultipleForm = (validation, data) => {
  console.log(data);
  const schema = Joi.object({ ...validation });
  const options = { abortEarly: false };

  let errors = [];
  let isValidForm = true;

  data.forEach((item) => {
    errors.push({ id: item.id, isValidForm: null });
    const indexError = errors.findIndex((e) => e.id === item.id);
    try {
      const validationResult = schema.validate(_.omit(item, ["id"]), options);
      console.log(validationResult);
      // No errors occurs
      if (!validationResult.error) {
        console.log("No error");
        for (const name in item) {
          errors[indexError][name] = false;
          errors[indexError].isValidForm = true;
        }

        return;
      }

      // Errors Occurred

      validationResult.error?.details?.forEach((err) => {
        errors[indexError][err.path[0]] = err.message;
        isValidForm = false;
      });

      errors[indexError].isValidForm = false;
      console.log(validationResult);
      return { errors, isValidForm: false };
    } catch (err) {
      return { any: "Something wrong happend", isValidForm: false };
    }
  });

  return { errors, isValidForm };
};
// Recaptcha
const validateHuman = () => {
  return 0;
};

export { validateForm, valdiateMultipleForm, validateProprety, validateHuman };
