import { useState, useEffect } from "react";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const usePersistLogin = () => {
  //states
  const [isLoading, setIsLoading] = useState(true);
  // Custom Hooks
  const refresh = useRefreshToken();
  const { dispatch, authAction, isLoggedIn } = useAuth();
  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        const token = await refresh();

        if (token?.accessToken) {
          dispatch(authAction.login(token));
          console.log("Set loggin");
        }
      } catch (err) {
        console.log(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !isLoggedIn ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  return { isLoggedIn, isLoading };
};

export default usePersistLogin;
