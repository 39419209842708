import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import useLanguages from "../../hooks/useLanguages";
import useHttpPrivate from "../../hooks/useHttpPrivate";
import { API_BACKEND } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { schema } from "./sendMailValidation";
import {
  valdiateMultipleForm,
  validateForm,
  validateProprety,
} from "../../utils/validation";

const SendMail = ({
  data,
  onValidate,
  onCancel,
  isOpen,
  isDeleting,
  orderId,
  event,
}) => {
  //const [isOpen, setIsOpen] = useState(false);
  const { currentLangCode } = useLanguages();
  const modalDivRef = useRef(null);
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const httpPrivate = useHttpPrivate();

  const handleDelete = () => {
    onValidate();
    //setIsOpen(false);
  };

  const onChangeHandler = (e) => {
    //setErrors(newError);
  };

  const sendEmail = async (e) => {
    //send email
    try {
      setIsLoading(true);
      const { data } = await httpPrivate.post(
        `${API_BACKEND}/booking/send-receipt`,
        { orderId, email }
      );

      setSuccess("email_sent_receipt");
      setError(null);
      setEmail("");
    } catch (err) {
      setSuccess();
      setError("wrong_email");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.2 }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: isOpen ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          ref={modalDivRef}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.2, delay: 0.1 }}
            style={{
              backgroundColor: "white",
              padding: "30px 50px",
              borderRadius: "0.5rem",
              boxShadow: "0 0 1rem rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              width: "450px",
            }}
          >
            <h4 style={{ fontWeight: "bold" }}>{t("send_to_email")}</h4>
            {error && <div className="alert alert-danger">{t(error)}</div>}
            {success && <div className="alert alert-success">{t(success)}</div>}
            <input
              className="form-control"
              placeholder="email"
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <div style={{ display: "flex", gap: "1rem" }}>
              {!isLoading && (
                <>
                  <button className="btn btn-success" onClick={sendEmail}>
                    {t("send")}
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      setEmail("");
                      setError(null);
                      setSuccess(null);
                      onCancel();
                    }}
                  >
                    {t("call_to_action.cancel")}
                  </button>
                </>
              )}
              {isLoading && (
                <div className="btn btn-danger">{t("loading")}</div>
              )}
            </div>
          </motion.div>
        </motion.div>
      }
    </>
  );
};

export default SendMail;
