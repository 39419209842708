import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ConfettiGenerator from "confetti-js";

import { useTranslation } from "react-i18next";
import { API_BACKEND } from "../utils/api";
import useHttpPrivate from "../hooks/useHttpPrivate";
import Loading from "../components/Loading";
import SendMail from "../components/modals/SendMail";

const Thankyou = (props) => {
  const { t } = useTranslation();
  // Accessing query parameters

  //const { search } = useLocation();
  //const query = new URLSearchParams(search);
  //const orderId = query.get("orderId");
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    ticketPath: null,
    invoicePath: null,
    approvalCode: null,
    orderId: null,
    orderNumber: null,
    totalPrice: null,
    date: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const httpPrivate = useHttpPrivate();

  useEffect(() => {
    const confettiSettings = {
      target: "confetti-holder",
      max: 103,
      size: 1,
      animate: true,
      props: ["circle", "square", "triangle", "line"],
      colors: [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126],
      ],
      clock: "35",
      rotate: true,
      start_from_edge: false,
      respawn: true,
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []); // add the var dependencies or not

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await httpPrivate.get(
          `${API_BACKEND}/booking/thankyou/${orderId}`
        );
        console.log(data);
        setData({
          ticketPath: data.ticketPath,
          invoicePath: data.invoicePath,
          approvalCode: data.approvalCode,
          orderNumber: data.orderNumber,
          orderId: data.orderId,
          date: data.date,
          totalPrice: data.totalPrice,
        });
      } catch (err) {
        console.log(err);
        navigate("/my-tickets");
      }
    };

    fetchData();
  }, []);

  const onCancel = () => {
    setIsOpen(false);
  };

  const onValidate = async () => {};
  return (
    <div className="thankyou">
      <SendMail
        isOpen={isOpen}
        onCancel={onCancel}
        onValidate={onValidate}
        orderId={orderId}
      />
      <canvas id="confetti-holder"></canvas>
      <div className="container">
        <div className="thankyou-box">
          <i className="fa-solid fa-circle-check checkmark"></i>
          <h1>{t("payment_success")}</h1>

          <h3 className="email-sent">{t("enjoy_visit")}!</h3>

          <div className="lines-1"></div>
          <div className="lines-2"></div>
          <div className="lines-3"></div>
          <div className="lines-4"></div>
          <div className="buttons">
            <a
              href={`${API_BACKEND}/templates/generated-html-ticketmockup-${data.orderNumber}.html`}
              target="_blank"
              className="btn btn-ticket"
            >
              {" "}
              <i className="fa-solid fa-ticket"></i>
              {t("download_ticket")}{" "}
            </a>{" "}
            <a
              href={data.invoicePath}
              className="btn btn-receipt"
              target="_blank"
            >
              <i className="fa-solid fa-file-invoice"></i>{" "}
              {t("download_your_receipt")}{" "}
            </a>
            <button
              className="btn btn-ticket"
              style={{ marginRight: "4px", marginLeft: "4px" }}
              onClick={(e) => setIsOpen(true)}
            >
              {t("send_to_email")}
            </button>
            <p>{t("call_satim")} </p>
            <img src="https://certweb.satim.dz/img/3020.png" />
          </div>
          <div className="new-order">
            <Link to="/" className="link-new-order">
              {t("back_home")} &gt;&gt;
            </Link>
          </div>
        </div>
        <div
          className="payment-info"
          style={{
            width: "77%",
            margin: "auto",
            marginTop: "25px",
          }}
        >
          <table class="table table-light">
            <caption>{t("payment_info")}</caption>
            <thead>
              <tr>
                <th scope="col">OrderId</th>
                <th scope="col">OrderNumber</th>
                <th scope="col">ApprovalCode</th>
                <th scope="col">Date</th>
                <th scope="col">Montant</th>
                <th scope="col">payment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.orderId}</td>
                <td>{data.orderNumber}</td>
                <td>{data.approvalCode}</td>
                <td>{data.date}</td>
                <td>{data.totalPrice}.00 DA</td>
                <td>CIB/Edhahabia</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
