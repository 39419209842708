import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import languages from "../i18n/languages";
import cookies from "js-cookie";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { langAction } from "../store/features/lang/langSlice";

const useLanguages = () => {
  const dispatch = useDispatch();
  const { currentLang, currentLangCode } = useSelector((state) => state.lang);
  /*
  const currentLangCode = cookies.get("i18next");
  const currentLang = languages.find((lang) => lang.code === currentLangCode);

  useEffect(() => {
    document.body.dir = currentLang.dir || "ltr";
  }, [currentLang, currentLangCode]);

  useEffect(() => {
    i18next.changeLanguage(currentLangCode);
  }, [currentLangCode]);

  return { currentLang, currentLangCode };*/
  const setLang = (code) => {
    //dispatch(langAction.setLanguage(Cookies.get("i18next")));
    dispatch(langAction.setLanguage(code));
  };
  return {
    currentLangCode,
    currentLang,
    dispatch,
    langAction,
    setLang,
  };
};

export default useLanguages;
