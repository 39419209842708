import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
const ColumnOne = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <div className="nav-column-1">
      <div className="logo"><img src="https://maht.dz/wp-content/uploads/2019/09/Webp.net-resizeimage-6.png" style={{
        height: "60px",
        width: "75px"
      }}/></div>
      <div className="links">
        <ul>
          <li>
            <Link className="normal-link" to="/">
              {t("menu.home")}
            </Link>
          </li>
          {user?.role === "admin" && (
            <>
              
              <li>
                <Link to="/dashboard/verifyTicket" className="normal-link">
                  {t("menu.verify_ticket")}
                </Link>
              </li>
              <li>
                <Link to="/dashboard/addTicketManually" className="normal-link">
                  {t("menu.add_visitor_manually")}
                </Link>
              </li>
              <li>
                <Link to="/dashboard/statistics" className="normal-link">
                  {t("menu.statistics")}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ColumnOne;
