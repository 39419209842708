import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import "moment/min/locales.min";

// Components
import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";
import Loading from "../components/Loading";
import Form from "../components/UI/form/Form";

// Hooks
import useLanguages from "../hooks/useLanguages";

// Services
import useHttpPrivate from "../hooks/useHttpPrivate";

import { API_BACKEND } from "../utils/api";
import AddManuallTicketModal from "../components/modals/AddManuallTicketModal";
import axios from "axios";

const AddTicketManually = () => {
  // Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();

  // Routing
  const { id } = useParams();
  const navigate = useNavigate();

  // Http private Auth
  const httpPrivate = useHttpPrivate();

  // States
  const [data, setData] = useState({
    visitorIntern: 0,
    visitorExtern:0,
    visitorResearcher: 0,
    visitorDelegation:0
  })
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)

  // Methods
// On Change Handler States
const onChangeHandler = (e, index, value) => {
    let nmbrVisitors = e.target.value < 0 ? 0 : e.target.value;

    const newData = {
      ...data,
      [e.target.name]: nmbrVisitors,
    };
  
    setData(newData);
  };

  const onCancel = () => {
    setIsOpen(false);
  }
  const onValidate = async() => {
   try{
    const { data: apiresponse } = await httpPrivate.post(
      `${API_BACKEND}/visitors/add_visitors`,
      {
        language: currentLangCode,
        visitorIntern: data.visitorIntern,
        visitorExtern:data.visitorExtern,
        visitorResearcher: data.visitorResearcher,
        visitorDelegation:data.visitorDelegation
      }
    );

    setIsOpen(false);
    setData({visitorIntern: 0,
      visitorExtern:0,
      visitorResearcher: 0,
      visitorDelegation:0});
 
      toast.success(t(apiresponse.message), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
   }catch(err){
    console.log(err)

   }
  }
  const onSubmitHandler = (e) => {
    e.preventDefault()
    const nbr_visitors = data.visitorIntern + data.visitorExtern + data.visitorResearcher + data.visitorDelegation
    if(nbr_visitors <= 0){
     setError(t("select_number_ticket_error_message"))
    } else{
      setError(false)
      setIsOpen(true)
    }
    
    
  }
  return (
    <>
      <NavbarResponisve />
      <AddManuallTicketModal data={data} isOpen={isOpen} onCancel={onCancel} onValidate={onValidate}/>
      <div className="booking">
        <div className="container">
         

          <div className="row">
           
              <div className="col-md-7">
              <h1 className="title">{t("add_visitor_manually")}</h1>

                <div className="booking-form">
                <>
      <Form onSubmit={onSubmitHandler} errorForm={error} isLoading={isLoading}>
        <div className="spectator-field">
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_intern")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorIntern}
              name="visitorIntern"
            />
          </div>
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_extern")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorExtern}
              name="visitorExtern"
            />
          </div>
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_researcher")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorResearcher}
              name="visitorResearcher"
            />
          </div>
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_delegation")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorDelegation}
              name="visitorDelegation"
            />
          </div>
          
        </div>
       
        {isLoading && (
          <div className="btn btn-primary mt-2">{t("loading")}</div>
        )}
        {!isLoading && (
          <button type="submit" className="btn btn-primary mt-2">
            {t("call_to_action.confirm")}
          </button>
        )}
      </Form>
      <ToastContainer />
    </>
                </div>
              </div>          
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTicketManually;
