import Joi from "joi";
import i18next from "i18next";
const schema = {
  visitorsGeneral: Joi.number()
    .integer()
    .min(0)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.required":
          case "number.base":
            err.message = i18next.t("validation.required");
            break;
        }
      });
      return errors;
    }),
  visitorsStudent: Joi.number()
    .integer()
    .min(0)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.required":
          case "number.base":
            err.message = i18next.t("validation.required");
            break;
        }
      });
      return errors;
    }),
  visitorsArtist: Joi.number()
    .integer()
    .min(0)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.required":
          case "number.base":
            err.message = i18next.t("validation.required");
            break;
        }
      });
      return errors;
    }),
    visitorsFree: Joi.number()
    .integer()
    .min(0)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.required":
          case "number.base":
            err.message = i18next.t("validation.required");
            break;
        }
      });
      return errors;
    }),
};

export { schema };
