import React, { useRef } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import useLanguages from "../../hooks/useLanguages";

const AddManuallTicketModal = ({
    data,
  onValidate,
  onCancel,
  isOpen,
  isDeleting,
  event,
  error,
}) => {
  //const [isOpen, setIsOpen] = useState(false);
  const { currentLangCode } = useLanguages();
  const modalDivRef = useRef(null);
  const { t } = useTranslation();

  const handleDelete = () => {
    onValidate();
    //setIsOpen(false);
  };

  return (
    <>

      {
        <motion.div
          
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.2 }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: isOpen ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          ref={modalDivRef}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.2, delay: 0.1 }}
            style={{
              backgroundColor: "white",
              padding: "30px 50px",
              borderRadius: "0.5rem",
              boxShadow: "0 0 1rem rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {error && (
              <div className="alert alert-danger">{t(error.message)}</div>
            )}
            <h4 style={{ fontWeight: "bold" }}>{t("ask_add_this_information")}</h4>
           
    
            <table className="table table-striped table-bordered">
    
      <tbody>
        <tr>
          <th>{t("nbr_visitors_intern")}</th>
          <td>{data.visitorIntern}</td>
        </tr>
        <tr>
          <th>{t("nbr_visitors_extern")}</th>
          <td>{data.visitorExtern}</td>
        </tr>
        <tr>
          <th>{t("nbr_visitors_researcher")}</th>
          <td>{data.visitorResearcher}</td>
        </tr>
        <tr>
          <th>{t("nbr_visitors_delegation")}</th>
          <td>{data.visitorDelegation}</td>
        </tr>
      </tbody>
    </table>

            <div style={{ display: "flex", gap: "1rem" }}>
              {!isDeleting && (
                <button className="btn btn-warning"  onClick={() => onCancel()}>
                  {t("call_to_action.cancel")}
                </button>
              )}
              {isDeleting && (
                <div className="btn btn-danger">{t("loading")}</div>
              )}
              <button className="btn btn-success" onClick={onValidate}>
                {t("call_to_action.confirm")}
              </button>
            </div>
          </motion.div>
        </motion.div>
      }
    </>
  );
};

export default AddManuallTicketModal;
  