import { useState } from "react";
import Footer from "../components/UI/Footer";
import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";
import useHttpPrivate from "../hooks/useHttpPrivate";
import { API_BACKEND } from "../utils/api";

import { ToastContainer, toast } from "react-toastify";
import { QrReader } from "react-qr-reader";

import { useTranslation } from "react-i18next";

import { is_free_day } from "../utils/functions";

const VerifyTicket = () => {
  const [booking_id, setBookingId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [ticketInfo, setTicketInfo] = useState(null);
  const [error, setError] = useState(null);

  const httpPrivate = useHttpPrivate();

  const { t } = useTranslation();

  const handleVerifyClick = async () => {
    setLoading(true);
    setError(null);
    setTicketInfo(null);
    try {
      const { data } = await httpPrivate.post(`${API_BACKEND}/verifyTicket`, {
        booking_id,
      });
      setTicketInfo(data.spectator);
      console.log(data.spectator);
    } catch (err) {
      console.log(err);
      if (
        err?.response?.data?.error?.code === "TICKET_ALREADY_VALIDATE" ||
        err?.response?.data?.error?.code === "BOOKING_NO_FOUND" ||
        err?.response?.data?.error?.code === "NOT_FREE_DAY"
      ) {
        setTicketInfo(err?.response?.data?.error?.spectator);

        setError({
          message: err?.response?.data?.error?.message,
          date: err?.response?.data?.error?.spectator?.validationDate,
          code: err.response.data.error["code"],
        });
        return;
      } else {
        toast.error("Something unknow happen refresh the page", {
          position: "bottom-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    setValidateLoading(true);
    try {
      const { data } = await httpPrivate.post(
        `${API_BACKEND}/verifyTicket/validate`,
        { booking_id }
      );

      toast.success(data.message, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toast.error("Something unknow happen refresh the page", {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setValidateLoading(false);
      setError(null);
      setTicketInfo(null);
    }
  };

  return (
    <>
      <NavbarResponisve />
      <div className="booking">
        <div className="container">
          <div className="verify-ticket">
            <div className="spectator-field">
              <div className="row">
                <div className="col-md-12">
                  <form className="verify-ticket-form">
                    <input
                      type="text"
                      value={booking_id}
                      onChange={(e) => setBookingId(e.target.value)}
                      placeholder="Enter ticket code"
                    />
                    <div>
                      <QrReader
                        facingMode={"environment"}
                        scanDelay={500}
                        onResult={(result, error) => {
                          if (!!result) {
                            setBookingId(result?.text);
                          }

                          if (!!error) {
                            console.info(error);
                          }
                        }}
                        containerStyle={{ width: "280px" }}
                        videoContainerStyle={{ width: "280px" }}
                        style={{ width: "300px" }}
                      />
                    </div>

                    <button type="button" onClick={handleVerifyClick}>
                      {t("cta_verify_ticket")}
                    </button>
                  </form>
                  {isLoading && (
                    <div className="verify-ticket-loading">
                      {t("verifying_ticket")}
                    </div>
                  )}
                  {error && (
                    <div className="verify-ticket-verification-status">
                      {error.code === "BOOKING_NO_FOUND" &&
                        t("ticket_no_found")}
                    </div>
                  )}
                  {ticketInfo && (
                    <div className="verify-ticket-info">
                      {error && (
                        <div className="verify-ticket-verification-status">
                          {error.code === "TICKET_ALREADY_VALIDATE" &&
                            t("ticket_validate_before") +
                              " " +
                              error["date"].split(" ")[0]}
                          {error.code === "NOT_FREE_DAY" && t("not_free_day")}
                          {error.code === "BOOKING_NO_FOUND" &&
                            t("ticket_no_found")}
                        </div>
                      )}
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("booking_type")}:{" "}
                        </div>
                        <div
                          className="verify-ticket-info-value fw-bold"
                          style={{ color: "#dc3545" }}
                        >
                          {" "}
                          {t(ticketInfo["bookingType"])}
                        </div>
                      </div>

                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("date_achat")}:{" "}
                        </div>
                        <div
                          className="verify-ticket-info-value fw-bold"
                          style={{ color: "#dc3545" }}
                        >
                          {ticketInfo["createdAt"].split(" ").shift()}
                        </div>
                      </div>

                      {!ticketInfo["validationStatus"] &&
                        !is_free_day() &&
                        error?.code !== "NOT_FREE_DAY" && (
                          <div className="verify-ticket-info-row">
                            {ticketInfo["bookingType"] === "student" && (
                              <div className="alert alert-danger fw-bold">
                                {t("student_must_show_id")}
                              </div>
                            )}
                            {ticketInfo["bookingType"] === "artist" && (
                              <div className="alert alert-danger fw-bold">
                                {t("artist_must_show_id")}
                              </div>
                            )}
                            {ticketInfo["bookingType"] === "free" && (
                              <div className="alert alert-danger fw-bold">
                                {t("free_must_show_id")}
                              </div>
                            )}
                          </div>
                        )}
                      {!!!error && (
                        <>
                          {validateLoading ? (
                            <button
                              className="verify-ticket-validate"
                              type="button"
                            >
                              {t("loading")}
                            </button>
                          ) : (
                            <button
                              className="verify-ticket-validate"
                              type="submit"
                              onClick={handleValidate}
                            >
                              {t("validate_ticket")}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VerifyTicket;
