import { useState, useEffect } from "react";
import * as moment from "moment";
import "moment/min/locales.min";

import useLanguages from "../hooks/useLanguages";

import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";

import Loading from "../components/Loading";
import Footer from "../components/UI/Footer";
import Header from "../components/home/Header";

const Home = () => {
  // States
  const [events, setEvents] = useState({});
  const [loading, setLoading] = useState(false);

  // Languages
  const { currentLangCode } = useLanguages();

  useEffect(() => {
    const langLocale = currentLangCode === "ar" ? "ar_DZ" : currentLangCode;
    moment.locale(langLocale);
  }, [currentLangCode]);

  return loading ? (
    <Loading />
  ) : (
    <div className="home">
      <NavbarResponisve />
      <Header />

      <Footer />
    </div>
  );
};

export default Home;
