const Footer = () => {
  return (
    <div className="footer">
      <span>
        All right reserved MUSEE D'ART ET D'HISTOIRE TLEMCEN{" "}
        {new Date().getFullYear()}
      </span>{" "}
      <span>
        Developped by{" "}
        <a href="https://easy-soft-tlemcen.com/" target="_blank">
          Easy Smart Soft
        </a>
      </span>
    </div>
  );
};

export default Footer;
