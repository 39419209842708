import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Header = () => {
  const { t } = useTranslation();
  const buyTicketBtnStyle = {
    padding: "15px 30px",
    borderRadius: "3px",
    background: "#198754",
    borer: "red",
    borderColor: "#093c25"
  }
  return (
    <div className="event-section">
      <div className="event-wrapper">
        <div>
        
          <div
            className="upcoming-match-text"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {t("header.description")}
          </div>
          <Link to="/booking" className="btn btn-primary" style={buyTicketBtnStyle}>{t("buy_ticket")}</Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
