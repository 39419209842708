import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import ReCAPTCHA from "react-google-recaptcha";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLanguages from "../../hooks/useLanguages";
import { bookingAction } from "../../store/features/booking/bookingSlice";
import Form from "../UI/form/Form";
import { schema } from "./validation";
import {
  valdiateMultipleForm,
  validateForm,
  validateProprety,
} from "../../utils/validation";

import { useEffect } from "react";
import useHttpPrivate from "../../hooks/useHttpPrivate";

import { API_BACKEND, API_FRONTEND } from "../../utils/api";
import { is_free_day, free_day_event } from "../../utils/functions";
import { useNavigate } from "react-router-dom";

const SetInformation = () => {
  // Booking Store
  const dispatch = useDispatch();

  const {
    totalPrice,
    visitors_general,
    visitors_artist,
    visitors_student,
    visitors_free,
  } = useSelector((state) => state.booking);
  //Http Private
  const httpPrivate = useHttpPrivate();
  // i18next-react Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();

  const navigate = useNavigate();

  // States

  const [data, setData] = useState({
    visitorsGeneral: visitors_general,
    visitorsArtist: visitors_artist,
    visitorsStudent: visitors_student,
    visitorsFree: visitors_free,
  });

  const [checkCondition, setCheckCondition] = useState(false);

  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // Recaptcha V2 States
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [shakeRecaptcha, setShakeRecaptcha] = useState(false);
  // Recaptcha V2 Element
  const recaptchaRef = useRef();

  // Make Shake Animation When Reacaptcha V2 not Checked
  useEffect(() => {
    const shakeTimeout = setTimeout(() => {
      setShakeRecaptcha(false);
    }, 500);

    // Clenup setTimeout
    return () => {
      clearTimeout(shakeTimeout);
    };
  }, [shakeRecaptcha]);

  // Functions

  // Reacaptcha OnChange Handler
  const onChangeRecaptcha = async (value) => {
    setRecaptchaToken(value);
    setError(false);
  };

  //calcul total price

  // On Change Handler States
  const onChangeHandler = (e, index, value) => {
    let nmbrVisitors = e.target.value < 0 ? 0 : e.target.value;
    console.log(isNaN(e.target.value));

    const newData = {
      ...data,
      [e.target.name]: nmbrVisitors,
    };
    const sumPrice =
      newData.visitorsArtist * 140 +
      newData.visitorsGeneral * 200 +
      newData.visitorsStudent * 100;
    if (
      parseInt(newData.visitorsArtist) +
        parseInt(newData.visitorsGeneral) +
        parseInt(newData.visitorsStudent) >=
      10
    ) {
      const promoPrice = sumPrice - sumPrice * 0.2;
      dispatch(bookingAction.setTotalPrice(promoPrice));
    } else {
      dispatch(bookingAction.setTotalPrice(sumPrice));
    }
    setData(newData);
  };

  // Form Submit Handler
  const onSubmitHandler = async (e) => {
    e.preventDefault();

    // Throw error message if 0 tickets selected
    if (
      parseInt(data.visitorsArtist) +
        parseInt(data.visitorsGeneral) +
        parseInt(data.visitorsStudent) +
        parseInt(data.visitorsFree) <=
      0
    ) {
      setError(t("validation.all_field_nbr_ticket"));
      return;
    }

    const { isValidForm } = validateForm(schema, data);

    if (!isValidForm) {
      setError(t("validation.all_field_nbr_ticket"));
      return;
    }

    //Check if checked Conidition
    if (!checkCondition) {
      setError(t("validation.checkCondition"));
      return 1;
    }

    // Validate Human
    if (!recaptchaToken) {
      setShakeRecaptcha(true);
      setError(t("validation.robot"));
      return 1;
    }

    setIsLoading(true);
    //dispatch(bookingAction.setInformation(data));
    const { visitorsGeneral, visitorsArtist, visitorsStudent, visitorsFree } =
      data;

    try {
      if (!is_free_day()) {
        // Check if only free visitors ( free access ticket )
        if (
          parseInt(data.visitorsArtist) +
            parseInt(data.visitorsGeneral) +
            parseInt(data.visitorsStudent) ==
            0 &&
          parseInt(data.visitorsFree) > 0
        ) {
          const { data } = await httpPrivate.post(
            `${API_BACKEND}/booking/create-free-access`,
            {
              language: currentLangCode,
              visitors_free: visitorsFree,
              recaptchaToken,
            }
          );

          navigate(`/thankyou/${data.order}`);
        } else {
          // purchase booking
          try {
            const { data } = await httpPrivate.post(
              `${API_BACKEND}/booking/create-booking`,
              {
                language: currentLangCode,
                totalPrice,
                visitors_general: visitorsGeneral,
                visitors_artist: visitorsArtist,
                visitors_student: visitorsStudent,
                visitors_free: visitorsFree,
                recaptchaToken,
              }
            );

            window.open(data.formUrl, "_blank") ||
              window.location.replace(data.formUrl);
          } catch (err) {
            setError(t(err.response.data.error.message));
          }
        }
      } else {
        // Free day event booking ( free days )
        const { data } = await httpPrivate.post(
          `${API_BACKEND}/booking/free-day`,
          {
            language: currentLangCode,
            visitors_general: visitorsGeneral,
            visitors_artist: visitorsArtist,
            visitors_student: visitorsStudent,
            visitors_free: visitorsFree,
            recaptchaToken,
          }
        );
        navigate(`/thankyou/${data.order}`);
      }
    } catch (err) {
      console.log(err.response.data.error);
      console.log(t(err.response.data.error.message));
      setError(t(err.response.data.error.message));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form onSubmit={onSubmitHandler} errorForm={error} isLoading={isLoading}>
        <div className="spectator-field">
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_general")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorsGeneral}
              name="visitorsGeneral"
            />
          </div>
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_artist")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorsArtist}
              name="visitorsArtist"
            />
          </div>
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_student")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorsStudent}
              name="visitorsStudent"
            />
          </div>
          <div className="form-control spectator-feild-to-choose">
            <label>{t("nbr_visitors_free")}</label>
            <input
              className=""
              onChange={onChangeHandler}
              type="number"
              value={data.visitorsFree}
              name="visitorsFree"
            />
          </div>
          <div className="ticket-price">
            <span>{t("total_price")}:</span>
            <span>
              {totalPrice}.00 {t("DA")}
            </span>
          </div>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => setCheckCondition(!checkCondition)}
              checked={checkCondition}
            />
            اوافق على{" "}
            <a
              href="https://maht.dz/%d8%b4%d8%b1%d9%88%d8%b7-%d8%a7%d9%84%d8%a7%d8%b3%d8%aa%d8%ae%d8%af%d8%a7%d9%85/"
              target="_blank"
            >
              شروط الإستخدام
            </a>
          </label>
        </div>
        {!isLoading && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcHVAopAAAAAHArWr06U3_DqRstBI0jzdDoeODf"
            onChange={onChangeRecaptcha}
          />
        )}
        {isLoading && (
          <div className="btn btn-primary mt-2">{t("loading")}</div>
        )}
        {!isLoading && (
          <button type="submit" className="btn btn-primary mt-2">
            {t("call_to_action.confirm")}
          </button>
        )}
      </Form>
      <ToastContainer />
    </>
  );
};

export default SetInformation;
