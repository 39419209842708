import { Routes, Route } from "react-router-dom";
import useLanguages from "./hooks/useLanguages.js";
import Cookies from "js-cookie";
import { useEffect } from "react";

import Home from "./pages/Home";
import Register from "./pages/Register";

import Login from "./pages/Login";

import ProtectedPages from "./pages/ProtectedPages";
import PersitLogin from "./components/PersistLogin";
import Booking from "./pages/Booking";
import Thankyou from "./pages/Thankyou";
import PaymentFailed from "./pages/PaymentFailed";

import SatimGateway from "./pages/tests/SatimeGateway.jsx";
import Checkout from "./pages/Checkout.jsx";
import MyTickets from "./pages/MyTickets.jsx";
import Ticket from "./pages/Ticket.jsx";

import VerifyTicket from "./pages/VerifyTicket.js";
import AddTicketManually from "./pages/AddTicketManually.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import ResetPassword from "./pages/ResetPassword.jsx";
import MyPorfile from "./pages/user/MyProfiel.jsx";
import ConfirmEmail from "./pages/user/ConfirmEmail.jsx";
import Statistics from "./pages/statistics.jsx";
import Confirmation from "./pages/Confirmation.jsx";

const App = () => {
  // Initial Langauge or setLang when User Change Language With react.query [ domain.com/somepage?lang=ar ]
  const { setLang } = useLanguages();

  useEffect(() => {
    console.log(Cookies.get("i18next"));
    setLang(Cookies.get("i18next"));
  }, []);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />

        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        <Route path="/ticketmockup" element={<Ticket />} />

        <Route path="/user/my-profile" element={<MyPorfile />} />

        {/*Make later as ProtectdPages*/}
        <Route path="/thankyou/:orderId" element={<Thankyou />} />
        <Route path="/paymentfailed" element={<PaymentFailed />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/satimgateway" element={<SatimGateway />} />

        <Route element={<PersitLogin />}>
          <Route element={<ProtectedPages allowedRoles={["admin", "user"]} />}>
            <Route path="/confirm" element={<Confirmation />} />
          </Route>
          <Route element={<ProtectedPages allowedRoles={["admin", "user"]} />}>
            <Route path="/my-tickets" element={<MyTickets />} />
          </Route>
          <Route element={<ProtectedPages allowedRoles={["admin", "user"]} />}>
            <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
          </Route>
          <Route element={<ProtectedPages allowedRoles={["admin", "user"]} />}>
            <Route path="/booking" element={<Booking />} />
          </Route>
          <Route element={<ProtectedPages allowedRoles={["admin"]} />}>
            <Route path="/dashboard/verifyTicket" element={<VerifyTicket />} />
          </Route>
          <Route element={<ProtectedPages allowedRoles={["admin"]} />}>
            <Route
              path="/dashboard/addTicketManually"
              element={<AddTicketManually />}
            />
          </Route>
          <Route element={<ProtectedPages allowedRoles={["admin"]} />}>
            <Route path="/dashboard/statistics" element={<Statistics />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
