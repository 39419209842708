import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const id = uuidv4();
const initialState = {
  checkInformation: false,
  visitors_general: 0,
  visitors_artist: 0,
  visitors_student: 0,
  visitors_free: 0,
  totalPrice: 0,

  ticket: {
    id: null,
    price: null,
  },
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setInformation: (state, { payload }) => {
      state.visitors_general = payload.visitorsGeneral;
      state.visitors_artist = payload.visitorsArtist;
      state.visitors_student = payload.visitorsStudent;
      state.visitors_free = payload.visitorsFree;
    },
    setTicket: (state, { payload }) => {
      state.ticket.id = payload.id;
      state.ticket.price = payload.price;
    },
    setTotalPrice(state, { payload }) {
      state.totalPrice = payload;
    },

    toogleCheckInformation: (state, payload) => {
      state.checkInformation = !state.checkInformation;
    },

    resetBooking: (state, payload) => {
      state.checkInformation = false;
      state.spectators = [
        {
          id,
          first_name: "",
          last_name: "",
          nin: "",
          address: "",
          gender: "Male",
          birthday: "",
        },
      ];
      state.spectators_errors = [
        {
          id,
          first_name: false,
          last_name: false,
          nin: false,
          address: false,
          gender: false,
          birthday: false,
        },
      ];
      state.ticket = {
        id: null,
        price: null,
      };
      state.totalPrice = 0;
    },
  },
});

export const bookingAction = bookingSlice.actions;
export default bookingSlice;
