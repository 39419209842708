import { t } from "i18next";
import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TicketItem from "../components/my-tickets/TicketItem";
import Pagination from "../components/Pagination";
import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";
//import { httpPrivate } from "../services/http";
import { API_BACKEND } from "../utils/api";
import Loading from "../components/Loading";

import useAuth from "../hooks/useAuth";
import useHttpPrivate from "../hooks/useHttpPrivate";
const MyTickets = () => {
  const [params] = useSearchParams();
  const page = params.get("page") || 1;
  const [currentPage, setCurrentPage] = useState(+page);
  const [tickets, setTickets] = useState([]);
  const [pages, setPages] = useState(10);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  //const { accessToken } = useAuth();

  const httpPrivate = useHttpPrivate();

  // Get data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        /* const { data } = await httpPrivate.post(
          `${API_BACKEND}/user/my-tickets?page=${page}`
        );*/
        const { data } = await httpPrivate.get(
          `${API_BACKEND}/user/my-tickets?page=${page}`
        );
        setTickets([...data.tickets]);
        setPages(data.total);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {" "}
          <NavbarResponisve />
          <div className="my-tickets">
            <div className="container-fluid">
              <div className="tickets" id="accordionTickets">
                <div>
                  <h3
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#000",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("menu.my_tickets")}:
                  </h3>
                </div>

                <TicketItem tickets={tickets} />

                {tickets.length === 0 && (
                  <div style={{ fontWeight: 600 }}>
                    {t("nbr_no_ticket_yet")}
                  </div>
                )}
              </div>
              <div className="mt-3">
                <Pagination
                  path="my-tickets"
                  currentPage={currentPage}
                  pages={pages}
                  changePage={setCurrentPage}
                  range={5}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MyTickets;
