import { useState } from "react";
import { useTranslation } from "react-i18next";
import useLanguages from "../../../hooks/useLanguages";
import useHttpPrivate from "../../../hooks/useHttpPrivate";
import { API_BACKEND } from "../../../utils/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToolbarConfirmEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();
  const httpPrivate = useHttpPrivate();

  const handleConfirmEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await httpPrivate.post(
        `${API_BACKEND}/user/confirm-email`,
        {
          i18next: currentLangCode,
        }
      );
      toast.success(t(data.message), {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      console.log(err);
      toast.error(t(err.response.error.message), {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="toolbar-confirm-email">
      {!isLoading && (
        <>
          {t("alert_confirm_email")}
          <span onClick={handleConfirmEmail}> {t("click_here")}</span>{" "}
        </>
      )}
      {isLoading && <>{t("loading")}</>}
    </div>
  );
};

export default ToolbarConfirmEmail;
