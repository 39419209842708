import { t } from "i18next";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { API_BACKEND } from "../utils/api";
import useHttpPrivate from "../hooks/useHttpPrivate";
import useLanguages from "../hooks/useLanguages";
import Loading from "../components/Loading";
const PaymentFailed = () => {
  const [actionCodeDescription, setActionCodeDescription] = useState("");
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const orderId = query.get("orderId");
  const httpPrivate = useHttpPrivate();
  const { currentLangCode } = useLanguages();
  useEffect(() => {
    console.log("Use eeffect");
    const fetchData = async () => {
      try {
        const { data } = await httpPrivate.post(
          `${API_BACKEND}/booking/confirm-booking`,
          {
            orderId,
            language: currentLangCode,
          }
        );

        setActionCodeDescription("");
      } catch (err) {
        setActionCodeDescription(
          err.response.data?.error?.ipay?.actionCodeDescription
        );
      }
    };
    fetchData();
  }, []);
  return (
    <div className="paymentfailed">
      <div className="container">
        <div className="paymentfailed-box" style={{ textAlign: "center" }}>
          <i class="fa-solid fa-circle-xmark"></i>
          <h1>{t("purchase_failed")}</h1>

          <p>{actionCodeDescription}</p>
          <p>{t("call_satim")} </p>
          <img src="https://certweb.satim.dz/img/3020.png" />
          <div className="lines-1"></div>
          <div className="lines-2"></div>
          <div className="lines-3"></div>
          <div className="lines-4"></div>
          <div className="btn-redirect">
            <Link to="/">{t("back_home")}&gt;&gt;</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
