const is_free_day = () => {
  let free_day = false;
  // Check free days
  const freeDays = [
    {
      month: "april",
      date: "04-18",
    },
    {
      month: "may",
      date: "05-18",
    },
    {
      month: "november",
      date: "11-01",
    },
    {
      month: "july",
      date: "07-03",
    },
    {
      month: "july",
      date: "07-04",
    },
    {
      month: "july",
      date: "07-05",
    },
    {
      month: "april",
      date: "04-16",
    },
    {
      month: "march",
      date: "03-08",
    },
  ];

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  for (let i in freeDays) {
    console.log(`${year}-${freeDays[i].date}`, formattedDate);
    if (`${year}-${freeDays[i].date}` === formattedDate) free_day = true;
  }

  return free_day;
};

const free_day_event = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  let day_event;
  if (`${year}-04-18` === formattedDate) {
    day_event = "free_date_18_april";
  }

  if (`${year}-05-18` === formattedDate) {
    day_event = "free_date_18_may";
  }
  if (`${year}-11-01` === formattedDate) {
    day_event = "free_date_1_november";
  }
  if (
    `${year}-07-03` === formattedDate ||
    `${year}-07-04` === formattedDate ||
    `${year}-07-05` === formattedDate
  ) {
    day_event = "free_date_05_july";
  }
  if (`${year}-04-16` === formattedDate) {
    day_event = "free_date_16_april";
  }
  if (`${year}-03-08` === formattedDate) {
    day_event = "free_date_08_march";
  }

  return day_event;
};

export { is_free_day, free_day_event };
