import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { bookingAction } from "../../store/features/booking/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import useLanguages from "../../hooks/useLanguages";

// Services

import { booking_places } from "../../services/booking";
import useHttpPrivate from "../../hooks/useHttpPrivate";
import http from "../../services/http";
import { API_BACKEND } from "../../utils/api";

const ConfirmInformation = () => {
  let { id } = useParams();

  //Http Private
  const httpPrivate = useHttpPrivate();

  // Confirmation Ref
  const confirmationRef = useRef();
  // States
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Recaptcha V2 States
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [shakeRecaptcha, setShakeRecaptcha] = useState(false);

  // Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();
  // Navigate ( for redirection )
  const navigate = useNavigate();

  // Booking Store
  const dispatch = useDispatch();
  const { visitors_general, visitors_artist, visitors_student, totalPrice } =
    useSelector((state) => state.booking);

  // Recaptcha V2 Element
  const recaptchaRef = useRef();

  // Make Shake Animation When Reacaptcha V2 not Checked
  useEffect(() => {
    const shakeTimeout = setTimeout(() => {
      setShakeRecaptcha(false);
    }, 500);

    // Clenup setTimeout
    return () => {
      clearTimeout(shakeTimeout);
    };
  }, [shakeRecaptcha]);

  // Scroll To errors

  /* useEffect(() => {
    if (errors) {
      confirmationRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  });*/

  /// Functions

  // Reacaptcha OnChange Handler
  const onChangeRecaptcha = async (value) => {
    setRecaptchaToken(value);
    setError(false);
  };

  // Form OnSubmit Handler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    // Validate Human
    if (!recaptchaToken) {
      setShakeRecaptcha(true);
      setError(t("validation.robot"));
      return 1;
    }
    setIsLoading(true);

    try {
      const { data } = await httpPrivate.post(
        `${API_BACKEND}/booking/create-booking`,
        {
          amount: totalPrice,
          language: currentLangCode,
          id_event: id,
          recaptchaToken,
        }
      );

      window.open(data.formUrl, "_blank") ||
        window.location.replace(data.formUrl);
    } catch (err) {
      console.log(t(err.response.data.error.message));
      setError(t(err.response.data.error.message));
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className="confirm-information"
      ref={confirmationRef}
      onSubmit={onSubmitHandler}
    >
      {error && <div className="alert alert-danger">{error}</div>}

      {
        <div className="spectator-field">
          <h4 className="spectator-count">عدد</h4>
          <div className="row">
            <div className="col-md-6">
              <p>{t("form.first_name")}</p>
              <p className="field">ééé</p>
            </div>
            <div className="col-md-6">
              <p>{t("form.last_name")}</p>
              <p className="field">éé</p>
            </div>
          </div>
          <div className="col-md-12">
            <p>{t("form.nin")}</p>
            <p className="field">ىهى</p>
          </div>
          <div className="col-md-12">
            <p>{t("form.address")}</p>
            <p className="field">ادراس</p>
          </div>
        </div>
      }

      {error && <div className="invalid-feedback">{error}</div>}
      {!isLoading && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcVVnwlAAAAABt6spzex8gKnjO2hnilYLZSyRiX"
          onChange={onChangeRecaptcha}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isLoading && (
          <button className="btn btn-form">
            {t("call_to_action.confirm")}
          </button>
        )}
        {isLoading && <div className="btn btn-form">{t("loading")}</div>}

        <button
          className="btn btn-primary btn-edit"
          onClick={() => dispatch(bookingAction.toogleCheckInformation())}
        >
          {t("call_to_action.modify")}
        </button>
      </div>
    </form>
  );
};

export default ConfirmInformation;
