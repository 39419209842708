import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/min/locales.min";
import useLanguages from "../../hooks/useLanguages";
import { API_BACKEND } from "../../utils/api";

const TicketItem = ({ tickets }) => {
  //Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();
  const [langLocale, setLangLocale] = useState(currentLangCode);
  const iconPlusRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  //change moment local
  useEffect(() => {
    setLangLocale(currentLangCode === "ar" ? "ar_DZ" : currentLangCode);

    moment.locale(currentLangCode === "ar" ? "ar_DZ" : currentLangCode);
  }, [currentLangCode]);
  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="ticket-item">
      <div className="ticket-body table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>{t("date_achat")}</th>
              <th>{t("nbr_visitors_general")}</th>
              <th>{t("nbr_visitors_artist")}</th>
              <th>{t("nbr_visitors_student")}</th>
              <th>{t("nbr_visitors_free")}</th>

              <th>{t("total_ticket")}</th>
              <th>{t("download_ticket")}</th>
              <th>{t("download_your_receipt")}</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket, index) => (
              <tr key={index}>
                <td>{ticket.createdAt.split(" ").shift()}</td>
                <td>{ticket.nbr_visitors_general}</td>
                <td>{ticket.nbr_visitors_artist}</td>
                <td>{ticket.nbr_visitors_student}</td>
                <td>{ticket.nbr_visitors_free}</td>
                <td>{ticket.quantity}</td>

                <td>
                  <a
                    target="_blank"
                    href={`${API_BACKEND}/templates/generated-html-ticketmockup-${ticket.orderNumber}.html`}
                    className="btn btn-primary download shadow"
                  >
                    {t("download_ticket")}
                  </a>
                </td>

                <td>
                  <a
                    target="_blank"
                    href={`${API_BACKEND}/invoices/${ticket.orderId}.pdf`}
                    className="btn btn-primary download shadow"
                  >
                    {t("download_your_receipt")}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketItem;
